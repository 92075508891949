import './auth-modal.scss'
import { useAuthModalController } from './auth-modal-controller.js'

import ButtonAtomComponent from '../../components/atoms/button-atom/button-atom-component.jsx'
import ModalOrganismComponent from '../../components/organisms/modal-organism/modal-organism-component.jsx'

export default function AuthModal (props) {
    const controller = useAuthModalController(props)
    return (
        <ModalOrganismComponent
            class="auth-modal"
            onClose={props.onClose}
            seamlessSections
        >
            <div class="sso">
                <section class="sso__logos">
                    <img class="sso__logo-icon" src="/assets/logo/lodgr-square-logo.svg" />
                    <img src="/assets/icons/arrow-switch.svg" />
                    <img class="sso__logo-icon" src="/assets/logo/scrawlr-square-logo.svg" />
                </section>
                <section class="sso__text">
                    <div class="sso__title">Log in or create an account</div>
                    <div class="sso__description">
                        Lodgr is part of the <span class="sso__description--bold">Scrawlr</span>{' '}
                        platform.<br />Access all Scrawlr products with one account.
                    </div>
                </section>
                <section class="sso__buttons">
                    <ButtonAtomComponent class="sso__login-button" onClick={controller.handleLogin}>
                        <img src="/assets/logo/scrawlr-circled.svg" />
                        <div>
                            Log in with Scrawlr
                        </div>
                    </ButtonAtomComponent>
                    <ButtonAtomComponent class="sso__email-button" onClick={controller.handleSignup}>
                        Continue with email instead
                    </ButtonAtomComponent>
                </section>
            </div>
        </ModalOrganismComponent>
    )
}
