// -- External libraries
import { splitProps } from 'solid-js'

import { useBarAtomComponentController } from './bar-atom-component-controller.js'
import './bar-atom-component.scss'

export default function BarAtomComponent (props) {
    const {
        mergedProps,
    } = useBarAtomComponentController(props)
    const requiredAttributes = ['direction']
    const [
        requestAttributes,
        styles,
        attributes,
    ] = splitProps(mergedProps, requiredAttributes, ['class'])

    const rootClasses = ['bar-atom-component']
    if (styles.class) rootClasses.push(styles.class)

    if (requestAttributes.direction === 'vertical') rootClasses.push('bar-atom-component--vertical')
    else rootClasses.push('bar-atom-component--horizontal')

    return (
        <div class={rootClasses.join(' ')} {...attributes}>
            {attributes.children}
        </div>
    )
}
