import {
    useLocation,
    useNavigate,
    useSearchParams,
} from '@solidjs/router'
import {
    createEffect,
    onCleanup,
} from 'solid-js'
import errorStore from '../../stores/error-store'
import sessionStore from '../../stores/session-store'
import userStore from '../../stores/user-store'
import SessionService from '../services/session-service'
import UserService from '../services/user-service'

export const useAuthSync = async () => {
    await SessionService.checkAuthentication()

    const handleStorageChange = async (event) => {
        if (event.key === 'session_access_token') {
            const currentAccessToken = sessionStore.getAccessToken()
            const newAccessToken = event.newValue

            if (currentAccessToken && newAccessToken && currentAccessToken !== newAccessToken) {
                window.location.href = '/login'
                return
            }

            await SessionService.checkAuthentication()
        }
    }

    window.addEventListener('storage', handleStorageChange)

    onCleanup(() => {
        window.removeEventListener('storage', handleStorageChange)
    })
}

export const useAuthGuard = async () => {
    const navigate = useNavigate()
    const {
        pathname,
        search,
    } = useLocation()

    return new Promise(async (resolve) => {
        const authenticated = SessionService.isAuthenticated()

        if (!authenticated) {
            navigate(`/login?from=${encodeURIComponent(pathname + search)}`, {
                replace: true,
            })
            return
        }

        resolve()

        createEffect(() => {
            if (!SessionService.isAuthenticated()) {
                console.log('User logged out. Redirecting...')
                navigate(`/login?from=${encodeURIComponent(pathname + search)}`, {
                    replace: true,
                })
            }
        })
    })
}

export const useVerificationGuard = async () => {
    try {
        // Ensure the user is loaded into the store
        const user = await userStore.getEmail()
        if (!user) {
            const userShowData = await UserService.show()
            if (userShowData) {
                userStore.setUser(userShowData)
            } else {
                throw new Error('Failed to fetch user details.')
            }
        }

        // Check verification status
        const isVerified = await userStore.getVerifiedAt()
        if (!isVerified) {
            const navigate = useNavigate()
            navigate('/verify', {
                replace: true,
            })
        }
    } catch (error) {
        if (error?.statusCode === 401) {
            await sessionStore.removeAccessToken()
        } else {
            console.error('useVerificationGuard - Error:', error)
            // errorStore.addError(error, 'useVerificationGuard')
        }
    }
}

export const appendSearchParams = (link) => {
    const [searchParameters] = useSearchParams()

    // Convert searchParams to a query string
    const parametersString = new URLSearchParams(searchParameters).toString()

    // Append existing params to the new link
    return parametersString ? `${link}?${parametersString}` : link
}

export const authTokenRedirect = (redirectUrl, authToken) => {
    if (!redirectUrl || !authToken) return

    const formattedUrl = redirectUrl.startsWith('http') ? redirectUrl : `https://${redirectUrl}`
    // Append auth token as a query param
    const url = new URL(formattedUrl)
    url.searchParams.set('auth_token', authToken)

    window.location = url
}
