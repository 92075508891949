// -- External libraries
import {
    Show,
    splitProps,
} from 'solid-js'

import { useCardAtomComponentController } from './card-atom-component-controller.js'
import './card-atom-component.scss'

export default function CardAtomComponent (props) {
    const {
        mergedProps,
    } = useCardAtomComponentController(props)
    const requiredAttributes = [
        'heading', 'subheading', 'shadow',
    ]
    const [
        requestAttributes,
        styles,
        attributes,
    ] = splitProps(mergedProps, requiredAttributes, ['class'])

    const rootClasses = ['card-atom-component']
    if (styles.class) rootClasses.push(styles.class)
    if (requestAttributes.shadow) rootClasses.push('card-atom-component--shadow')

    return (
        <div class={rootClasses.join(' ')} {...attributes}>
            <Show when={requestAttributes.heading}>
                <h2 class="card-atom-component__heading">
                    {requestAttributes.heading}
                </h2>
            </Show>
            <Show when={requestAttributes.subheading}>
                <div class="card-atom-component__subheading">
                    {requestAttributes.subheading}
                </div>
            </Show>
            <div class="card-atom-component__content">
                {attributes.children}
            </div>
        </div>
    )
}
