// -- External libraries
import { splitProps } from 'solid-js'

import { useImageAtomComponentController } from './image-atom-component-controller.js'
import './image-atom-component.scss'

export default function ImageAtomComponent (props) {
    const {
        mergedProps,
    } = useImageAtomComponentController(props)
    const requiredAttributes = [
        'src', 'alt', 'placeholder',
    ]
    const [
        requestAttributes,
        styles,
        attributes,
    ] = splitProps(mergedProps, requiredAttributes, ['class'])

    const rootClasses = ['image-atom-component']
    if (styles.class) rootClasses.push(styles.class)

    return (
        <img
            class={rootClasses.join(' ')}
            src={requestAttributes.src}
            alt={requestAttributes.alt}
            {...attributes}
            onError={(e) => {
                e.target.src = requestAttributes.placeholder
            }}
        />
    )
}
