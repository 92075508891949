import './currency-dropdown.scss'
import {
    Show,
    For,
} from 'solid-js'
import { useCurrencyDropdownController } from './currency-dropdown-controller.js'

import ButtonAtomComponent from '../../atoms/button-atom/button-atom-component.jsx'
import ImageAtomComponent from '../../atoms/image-atom/image-atom-component.jsx'
import SpinnerAtomComponent from '../../atoms/spinner-atom/spinner-atom-component.jsx'

const CurrencyDropdown = () => {
    const controller = useCurrencyDropdownController()

    return (
        <div class="dropdown" ref={controller.setDropdownReference}>
            <ButtonAtomComponent
                class="dropdown__button"
                classList={{
                    'dropdown__button--active': controller.isOpen(),
                }}
                disabled={!controller.isBookingReady()}
                onClick={controller.toggleDropdown}
            >
                <Show when={controller.bookingStore.getSelectedCurrency()} fallback={<SpinnerAtomComponent />}>
                    <span class="dropdown__label">{controller.bookingStore.getSelectedCurrency()?.code}</span>
                    <div class="dropdown__arrow">
                        <ImageAtomComponent src="/assets/icons/dropdown-arrow-down.svg" />
                    </div>
                </Show>
            </ButtonAtomComponent>

            {controller.isOpen() && (
                <ul
                    class={`dropdown__menu dropdown__menu--${controller.position()}`}
                    ref={controller.setDropdownMenuReference}
                    tabIndex="-1"
                >
                    <li class="dropdown__header">Currency</li>
                    <For each={Object.values(controller.bookingStore.get('supportedCurrencies'))}>{(currency) => (
                        <li
                            class="dropdown__item"
                            classList={{
                                'dropdown__item--selected':
                                    controller.bookingStore.getSelectedCurrency()?.code === currency.code,
                            }}
                            onClick={() => controller.selectCurrency(currency)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    controller.selectCurrency(currency)
                                }
                            }}
                            tabIndex="0"
                        >
                            {currency.name}
                        </li>
                    )}</For>
                </ul>
            )}
        </div>
    )
}

export default CurrencyDropdown
