import errorStore from '../stores/error-store'
import timezones from '../app/data/static/timezones.json'

export const debounceAsync = (function_, delay) => {
    let timeoutId

    return async function (...arguments_) {
        return new Promise((resolve) => {
            clearTimeout(timeoutId)

            timeoutId = setTimeout(async () => {
                const resultValue = await function_.apply(this, arguments_)
                resolve(resultValue)
            }, delay)
        })
    }
}

export const debounce = (function_, delay) => {
    let timeout
    return (...arguments_) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => function_(...arguments_), delay)
    }
}

// convert to title case for all words not using regex
export const toTitleCase = (string_) => {
    return string_.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
}

export const generateDaysArray = (startDate, endDate, startTimezone = 'UTC', endTimezone = 'UTC') => {
    const start = new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: '2-digit',
        timeZone: startTimezone,
        year: 'numeric',
    }).format(new Date(startDate))

    const end = new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: '2-digit',
        timeZone: endTimezone,
        year: 'numeric',
    }).format(new Date(endDate))

    console.log(startTimezone, endTimezone)
    console.log(startDate, endDate, start, end)

    const newStartDate = new Date(start)
    const newEndDate = new Date(end)

    console.log(newStartDate, newEndDate)

    // Set the time to midnight to ignore time considerations
    newStartDate.setHours(0, 0, 0, 0)
    newEndDate.setHours(0, 0, 0, 0)

    const daysArray = []

    for (let dt = new Date(newStartDate); dt <= newEndDate; dt.setDate(dt.getDate() + 1)) {
        daysArray.push(new Date(dt)) // Push the date or a specific format of the date
    }

    return daysArray
}

export const generateTimeList = () => {
    try {
        const times = []
        const startTime = new Date()
        startTime.setHours(0, 0, 0, 0) // Set start time to 12:00 AM

        // Loop for 96 increments (24 hours * 4 increments per hour = 96)
        for (let index = 0; index < 96; index++) {
            const hours = startTime.getHours()
            const minutes = startTime.getMinutes()
            const ampm = hours >= 12 ? 'PM' : 'AM'
            const formattedHours = hours % 12 === 0 ? 12 : hours % 12 // Convert 24-hour format to 12-hour format
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

            times.push(`${formattedHours}:${formattedMinutes} ${ampm}`)

            startTime.setMinutes(startTime.getMinutes() + 15) // Increment by 15 minutes
        }

        return times
    } catch (error) {
        console.error(error)
        errorStore.addError(error, 'generateTimeList in usePropertyPageController')
    }
}

export const formatTime = (dateString, timezone = 'UTC') => {
    const date = new Date(dateString)

    const options = {
        hour: 'numeric',
        hour12: true,
        minute: '2-digit',
        timeZone: timezone,
    }

    return new Intl.DateTimeFormat('en-US', options).format(date).toLowerCase()
}

export const formatDate = (dateString, timezone = 'UTC') => {
    const date = new Date(dateString)

    const options = {
        day: '2-digit',
        month: 'short',
        timeZone: timezone,
        weekday: 'short',
        year: 'numeric',
    }

    return new Intl.DateTimeFormat('en-US', options).format(date)
}

export const formatDateTime = (dateString, timezone) => {
    return `${formatTime(dateString, timezone)} • ${formatDate(dateString, timezone)}`
}

export const formatDateRange = (checkIn, checkOut, timezone = 'UTC') => {
    const checkInDate = new Date(checkIn)
    const checkOutDate = new Date(checkOut)

    const options = {
        day: '2-digit',
        month: 'short',
        timeZone: timezone,
    }

    const formattedCheckIn = new Intl.DateTimeFormat('en-US', options).format(checkInDate)
    const formattedCheckOut = new Intl.DateTimeFormat('en-US', options).format(checkOutDate)

    if (checkInDate.getFullYear() === checkOutDate.getFullYear()) {
        return `${formattedCheckIn} – ${formattedCheckOut}, ${checkInDate.getFullYear()}`
    } else {
        const yearOptions = {
            ...options,
            year: 'numeric',
        }
        const formattedCheckInWithYear = new Intl.DateTimeFormat('en-US', yearOptions).format(checkInDate)
        const formattedCheckOutWithYear = new Intl.DateTimeFormat('en-US', yearOptions).format(checkOutDate)
        return `${formattedCheckInWithYear} – ${formattedCheckOutWithYear}`
    }
}

export const statusPrint = (status) => {
    return toTitleCase(status)
}

const isTomorrow = (startingDate) => {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    return startingDate.toDateString() === tomorrow.toDateString()
}

export const getUpdatedStatus = (status, checkin, checkout) => {
    if (status.toLowerCase() === 'confirmed') return statusPrint(status)
    const now = new Date()
    const checkinDate = new Date(checkin)
    const checkoutDate = new Date(checkout)

    if (isTomorrow(checkinDate)) return 'Arrives Tomorrow'
    if (checkinDate.toDateString() === now.toDateString()) return 'Checking In'
    if (checkoutDate.toDateString() === now.toDateString()) return 'Checking Out'
    if (now > checkinDate && now < checkoutDate) return 'Hosting'
    if (now > checkoutDate) return 'Completed'
    return statusPrint(status)
}

export function deepClone (object) {
    if (object === null || typeof object !== 'object') {
        return object
    }
    if (Array.isArray(object)) {
        return object.map(deepClone)
    }
    const clonedObject = {}
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            clonedObject[key] = deepClone(object[key])
        }
    }
    return clonedObject
}

export function deepChanges (object1, object2, path = '') {
    let changes = {}
    if (object1 === object2) {
        return changes
    }
    if (object1 === null || object2 === null || typeof object1 !== 'object' || typeof object2 !== 'object') {
        changes[path] = object2
        return changes
    }
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    const allKeys = new Set([...keys1, ...keys2])

    for (const key of allKeys) {
        const newPath = path ? `${path}.${key}` : key
        if (!keys1.includes(key)) {
            changes[newPath] = object2[key]
        } else if (keys2.includes(key)) {
            const nestedChanges = deepChanges(object1[key], object2[key], newPath)
            if (Object.keys(nestedChanges).length > 0) {
                changes = {
                    ...changes,
                    ...nestedChanges,
                }
            }
        } else {
            changes[newPath] = undefined
        }
    }

    return changes
}

export const provinces = [
    '--',
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NS',
    'ON',
    'PE',
    'QC',
    'SK',
    'NT',
    'NU',
    'YT',
]

export const americanStates = [
    '--',
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
]

export const convertToNumber = (currencyString) => {
    const numericString = currencyString.replaceAll(/[^\d.-]/g, '')
    return Number.parseFloat(numericString)
}

export const cancelPolicies = [
    {
        description: 'Full refund up to 24 hours before check-in.',
        label: 'Flexible',
        value: 'flexible',
    },
    {
        description: 'Full refund up to 5 days before check-in.',
        label: 'Moderate',
        value: 'moderate',
    },
    {
        description: 'Full refund up to 30 days before check-in.',
        label: 'Firm',
        value: 'firm',
    },
    {
        description: 'Full refund only within 48 hours of booking.',
        label: 'Strict',
        value: 'strict',
    },
]

export const addprotocol = (url) => {
    // Check if the URL starts with a valid protocol (http:// or https://)
    if (!/^https?:\/\//i.test(url)) {
        // If not, prepend https:// as a default protocol
        return 'https://' + url
    }
    // Return the URL if it already has a valid protocol
    return url
}

export const getTimezoneDisplayWithOffset = (timezone) => {
    try {
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: timezone,
            timeZoneName: 'longOffset', // e.g. "GMT-07:00"
        })

        const parts = formatter.formatToParts(new Date())
        const utcOffset = parts.find(part => part.type === 'timeZoneName').value

        return `${utcOffset} — ${timezone.split('/')[1].replaceAll('_', ' ')}`
    } catch (error) {
        console.error(`Error processing timezone: ${timezone}`, error)
        return `Invalid timezone: ${timezone}`
    }
}

export const getTimezoneOffsetInHours = (timezone, dateString) => {
    try {
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: timezone,
            timeZoneName: 'shortOffset', // e.g. "GMT-7"
        })

        const parts = formatter.formatToParts(new Date(dateString))
        const utcOffset = parts.find(part => part.type === 'timeZoneName').value
        return Number.parseInt(utcOffset.split(':')[0].replace('GMT', ''))
    } catch (error) {
        console.error(`Error processing timezone: ${timezone}`, error)
        return null
    }
}

export const formattedTimezoneSelections = timezones.map((timezone) => {
    return {
        label: getTimezoneDisplayWithOffset(timezone),
        value: timezone,
    }
})

export const getUserIanaTimezone = () => {
    try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (error) {
        console.error('Error getting user timezone', error)
        return null
    }
}

export const convertTo24HourFormat = (timeString) => {
    const [
        time,
        modifier,
    ] = timeString.split(' ')
    let [
        hours,
        minutes,
    ] = time.split(':').map(Number)

    if (modifier === 'PM' && hours !== 12) {
        hours += 12
    } else if (modifier === 'AM' && hours === 12) {
        hours = 0
    }

    // Pad hours and minutes with leading zeros if necessary
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

export const createUTCDateFromLocal = (dateString, timeString, timeZone) => {
    console.log(dateString, timeString, timeZone)
    const [
        hours,
        minutes,
    ] = convertTo24HourFormat(timeString).split(':').map(Number)
    const offsetHours = getTimezoneOffsetInHours(timeZone, dateString)
    const [
        year,
        month,
        day,
    ] = dateString.split('-').map(Number)

    console.log(year, month, day, hours, minutes, offsetHours)
    const localDate = new Date(Date.UTC(year, month - 1, day, hours, minutes))

    localDate.setUTCHours(localDate.getUTCHours() - offsetHours)

    return localDate
}
