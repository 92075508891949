import { useSpinnerAtomComponentController } from './spinner-atom-component-controller.js'
import './spinner-atom-component.scss'

import ImageAtomComponent from '../image-atom/image-atom-component'

export default function SpinnerAtomComponent (props) {
    const controller = useSpinnerAtomComponentController(props)

    return (
        <i
            class={controller.rootClasses.join(' ')}
            alt="Loading"
            {...controller.attrs}
        />
    )
}
