import sessionStore from '../../stores/session-store'
import SessionAPI from '../data/session-api-client'

import { createSignal } from 'solid-js'

export default class SessionService {
    // TODO: Re-evaluate the use of this service and how it interacts with the store
    static #isAuthenticatedSignal = createSignal(false)
    static isAuthenticated = SessionService.#isAuthenticatedSignal[0]
    static #setIsAuthenticated = SessionService.#isAuthenticatedSignal[1]

    static async store (email, password) {
        try {
            const {
                access_token,
            } = await SessionAPI.store({
                email,
                password,
            })
            await sessionStore.setAccessToken(access_token)
            this.#setIsAuthenticated(true)
            return access_token
        } catch (error) {
            console.error('SessionAPI.store - Error:', error)
            throw error
        }
    }

    static async delete () {
        await SessionAPI.delete()
        await sessionStore.removeAccessToken()
        this.#setIsAuthenticated(false)
    }

    static async checkAuthentication () {
        const token = await sessionStore.getAccessToken()
        this.#setIsAuthenticated(Boolean(token))
    }
}
