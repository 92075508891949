// -- External libraries
import { splitProps } from 'solid-js'

import { useMenuMoleculeComponentController } from './menu-molecule-component-controller.js'
import './menu-molecule-component.scss'
import LinkAtomComponent from '../../atoms/link-atom/link-atom-component.jsx'

export default function MenuMoleculeComponent (props) {
    const {
        mergedProps,
    } = useMenuMoleculeComponentController(props)
    const requiredAttributes = ['items', 'direction']
    const [
        requestAttributes,
        styles,
        attributes,
    ] = splitProps(mergedProps, requiredAttributes, ['class'])

    const rootClasses = ['menu-molecule-component']
    if (styles.class) rootClasses.push(styles.class)

    if (requestAttributes.direction === 'vertical') rootClasses.push('menu-molecule-component--vertical')
    else rootClasses.push('menu-molecule-component--horizontal')

    return (
        <ul class={rootClasses.join(' ')}>
            {
                /* <For each={reqAttrs.items}>
                {(item) =>
                    <LinkAtomComponent
                        class={'menu-molecule-component__link'
                            + (item.disabled ? ' menu-molecule-component__link--disabled' : '')
                            + (item.href === location.pathname || item.href === location.pathname + location.hash  ? ' menu-molecule-component__link--active' : '')
                        }
                        href={item.disabled ? '' : item.href}
                        external={item.external}
                        {...item.props}
                    >
                        {item.label}
                    </LinkAtomComponent>
                }
            </For> */
            }
            {/* show content */}
            {attributes.children}
        </ul>
    )
}
