export const fetchResponseHandler = async (response) => {
    const responseJSON = await response.json()

    if (!response.ok) {
        let error = new Error('Unexpected Error')

        if (responseJSON) {
            error = new Error(responseJSON.message)
            error.statusCode = responseJSON.status_code ?? responseJSON.statusCode
            error.data = responseJSON.data
        }

        throw error
    }

    return responseJSON
}
