import BaseBrowserStore from './base/base-browser-store.js'

class UserStore extends BaseBrowserStore {
    constructor (user = {}) {
        super('user')
    }

    async setUser (user) {
        await this.setEmail(user.email)
        await this.setVerifiedAt(user.verified_at)
    }

    async getEmail () {
        return await this.get('email')
    }

    async setEmail (email) {
        await this.set('email', email)
    }

    async getVerifiedAt () {
        return await this.get('verified_at')
    }

    async setVerifiedAt (verified_at) {
        await this.set('verified_at', verified_at)
    }

    async clear () {
        await this.remove('email')
        await this.remove('verified_at')
    }
}

const userStore = new UserStore()
export default userStore
