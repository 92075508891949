import CheckoutAPI from '../data/checkout-api-client.js'

export default class CheckoutService {
    static async show (id) {
        try {
            return await CheckoutAPI.show(id)
        } catch (error) {
            console.error(`Error fetching session with ID: ${id} -`, error)
            throw error
        }
    }

    static async create (bookingData) {
        try {
            const {
                url,
            } = await CheckoutAPI.create(bookingData)
            return url
        } catch (error) {
            console.error('Error creating session -', error)
            throw error
        }
    }

    static async checkCurrency (code) {
        try {
            const {
                data,
            } = await CheckoutAPI.currencies(code)

            return data
        } catch (error) {
            throw error
        }
    }
}
