// -- External libraries
import {
    mergeProps,
    splitProps,
} from 'solid-js'

export const useSpinnerAtomComponentController = (props) => {
    const defaultProps = {}
    const mergedProps = mergeProps(defaultProps, props)

    const requiredAttributes = []
    const [
        requestAttributes,
        styles,
        attributes,
    ] = splitProps(mergedProps, requiredAttributes, ['class'])

    const rootClasses = ['spinner-atom-component icon icon-loading-filled']
    if (styles.class) rootClasses.push(styles.class)

    return {
        attrs: attributes,
        reqAttrs: requestAttributes,
        rootClasses,
    }
}
