export function useFooterOrganismComponentController (props) {
    const footerSections = [
        {
            links: [
                {
                    external: false,
                    href: '/',
                    name: 'home',
                    text: 'Home',
                },
                // { name: 'about-us', text: 'About Us', href: '/about-us', external: false },
                {
                    external: false,
                    href: '/faq/general',
                    name: 'faq',
                    text: 'FAQ',
                },
            ],
            title: 'Lodgr',
        },
        {
            links: [
                {
                    external: false,
                    href: '/faq/general',
                    name: 'general',
                    text: 'General',
                },
                {
                    external: false,
                    href: '/faq/bookings',
                    name: 'bookings',
                    text: 'Bookings',
                },
                {
                    external: false,
                    href: '/faq/pricing',
                    name: 'payments-and-fees',
                    text: 'Payments & Fees',
                },
                {
                    external: false,
                    href: '/faq/hosting',
                    name: 'hosting',
                    text: 'Hosting',
                },
            ],
            title: 'FAQ',
        },
        {
            links: [
                {
                    external: false,
                    href: '/tos',
                    name: 'terms-and-conditions',
                    text: 'Terms & Conditions',
                },
                {
                    external: false,
                    href: '/privacy-policy',
                    name: 'privacy-policy',
                    text: 'Privacy Policy',
                },
                {
                    external: false,
                    href: '/',
                    name: 'contact',
                    text: 'Contact',
                },
            ],
            title: '© 2022',
        },
    ]

    return {
        footerSections,
    }
}
