import BaseAPI from './base-api-client.js';
import { fetchResponseHandler } from '../data/error-utils.js'

export default class CheckoutAPI extends BaseAPI {

    static async show (bookingId) {
        try {
            const response = await BaseAPI.fetch(`/checkout/${bookingId}`);
            if (!response.ok) {
                throw new Error(`Failed to fetch offer with ID: ${bookingId}.`);
            }
            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error(`Error fetching offer with ID: ${bookingId} -`, error);
            throw error;
        }
    }

    static async create (data) {
        try {
            const response = await BaseAPI.fetchSecure('/checkout', {
                body: data, method: 'post',
            });
            if (!response.ok) {
                throw new Error('Failed to create booking.');
            }
            const responseData = await response.json();
            return responseData.data;
        } catch (error) {
            console.error('Error creating booking -', error);
            throw error;
        }
    }

    static async currencies (code) {
        try {
            const response = await this.fetch(`/checkout/currencies/${code}`, {
                method: 'GET',
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

}

