import BaseBrowserStore from './base/base-browser-store'

class SessionStore extends BaseBrowserStore {
    constructor (session = {}) {
        super('session')
    }

    async getAccessToken () {
        return await this.get('access_token')
    }

    async setAccessToken (token) {
        await this.set('access_token', token)
    }

    async removeAccessToken () {
        await this.remove('access_token')
    }

    async clear () {
        await this.removeAccessToken()
    }
}

const sessionStore = new SessionStore()
export default sessionStore
