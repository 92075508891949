import PropertyAPI from '../data/property-api-client'

export default class PropertyService {
    static async create () {
        try {
            const {
                data: propertyId,
            } = await PropertyAPI.create()
            const {
                data: property,
            } = await PropertyAPI.show(propertyId)

            return property
        } catch (error) {
            throw error
        }
    }

    static async show (id) {
        try {
            const {
                data: property,
            } = await PropertyAPI.show(id)
            return property
        } catch (error) {
            console.error('PropertyAPI.show - Error:', error)
            throw error
        }
    }

    static async connect ({
        property_id,
        listing_url,
    }) {
        try {
            await PropertyAPI.update(property_id, {
                listing_url,
            })
            await PropertyAPI.connect(property_id)
            const {
                data: property,
            } = await PropertyAPI.show(property_id)

            return property
        } catch (error) {
            throw error
        }
    }

    static async list () {
        try {
            const {
                data,
            } = await PropertyAPI.list()

            return data || []
        } catch (error) {
            throw error
        }
    }

    static async search (propertyDate) {
        try {
            const {
                data,
            } = await PropertyAPI.search(propertyDate)

            return data[0] || []
        } catch (error) {
            throw error
        }
    }

    static async disconnect (id) {
        try {
            await PropertyAPI.disconnect(id)
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    static async updateProperty (id, data) {
        try {
            await PropertyAPI.update(id, data)
            const {
                data: property,
            } = await PropertyAPI.show(id)

            return property
        } catch (error) {
            throw error
        }
    }

    static async contact (message, propertyId, authRequired) {
        try {
            if (!propertyId || propertyId === '') throw new Error('No property_id provided')
            if (!message || message === '') throw new Error('No message provided')
            return await PropertyAPI.contact(message, propertyId, authRequired)
        } catch (error) {
            console.error(`Error sending message to recipient of property with ID: ${propertyId} -`, error)
            throw error
        }
    }

    static async checkAvailability (propertyId, body) {
        try {
            if (!propertyId || propertyId === '') throw new Error('No property_id provided')
            if (!body.url) throw new Error('Target URL is required')

            const {
                data,
            } = await PropertyAPI.checkAvailability(propertyId, body)
            return data
        } catch (error) {
            console.error(`Error checking availability for property ID: ${propertyId} -`, error)
            throw error
        }
    }

    static async getAvailability (propertyId) {
        try {
            const {
                data,
            } = await PropertyAPI.getAvailability(propertyId)
            return data
        } catch (error) {
            console.error('PropertyService: Error fetching availability', error)
            throw error
        }
    }
}
