import environment from '../../../.configs/app/environment.js'
import sessionStore from '../../stores/session-store.js'

class BaseAPI {
    static async fetch (endpoint, options = {}) {
        if (environment.api.base === 'mock') {
            console.log('BaseAPI.fetch API_MODE === mock', endpoint)
            return this.fetchMock(endpoint)
        }
        console.log('BaseAPI.fetch return', environment.api.base + endpoint)
        return fetch(environment.api.base + endpoint, {
            body: JSON.stringify(options.body),
            headers: {
                'Content-Type': 'application/json',
            },
            method: options.method,
        })
    }

    static async fetchSecure (endpoint, options = {}) {
        const headers = {
            Authorization: 'Bearer ' + (await sessionStore.getAccessToken()),
            ...options.headers || (options.body && {
                'Content-Type': 'application/json',
            }),
        }
        try {
            const response = await fetch(environment.api.base + endpoint, {
                body: options.multipart ? options.body : JSON.stringify(options.body),
                headers,
                method: options.method,
            })

            if (!response.ok) {
                throw new Error(response.message)
            }

            return response
        } catch (error) {
            throw error
        }
    }

    static async fetchMock (url) {
        console.log('BaseAPI.fetchMock')
        const [
            path,
            queryParameters,
        ] = url.split('?')
        const mockFilePath = `/mocks/api${path.replaceAll(/[^\w./-]/g, '_')}_${queryParameters.replaceAll(/[^\w.-]/g, '_')}.js`

        console.log('BaseAPI.fetchMock', mockFilePath)

        try {
            const mockData = await import(mockFilePath)
            console.log('BaseAPI.fetchMock', mockData)
            return mockData.default
        } catch (error) {
            console.error('BaseAPI.fetchMock Failed to load mock data:', error)
            throw error
        }
    }
}

export default BaseAPI