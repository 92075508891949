import environment from '../../../../.configs/app/environment.js'

export const useAuthModalController = (props) => {
    const digestMessage = async function (message) {
        const messageUint8 = new TextEncoder().encode(message)
        const hashBuffer = await crypto.subtle.digest('SHA-256', messageUint8)
        const hashArray = [...new Uint8Array(hashBuffer)]
        return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
    }
    const getRedirectUrl = async function () {
        const requestParameters = new URLSearchParams({
            client_id: environment.sso.clientId,
            code_challenge: await digestMessage('12345abc'),
            code_challenge_method: environment.sso.codeChallengeMethod,
            redirect_url: `${environment.api.base}/user/sso`,
            response_type: environment.sso.responseType,
            scope: 'read__email,read__name',
            state: environment.sso.state,
        })
        return `${environment.sso.authUrl}/v1/sso/authorize?${requestParameters.toString()}`
    }
    const handleLogin = async () => {
        const redirectUrl = await getRedirectUrl()
        window.location = redirectUrl

        props.onClose()
    }
    const handleSignup = async () => {
        window.location = '/login'
    }

    return {
        handleLogin,
        handleSignup,
    }
}
