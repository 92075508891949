class ErrorModel {
    #message
    #stack
    #error

    constructor (error) {
        this.#message = error.message
        this.#stack = error.stack
        this.#error = error
    }

    getMessage () {
        return this.#message
    }

    // TODO: Make errors bubble up the stack and display human-readable stack
    getStack () {
        return this.#stack
    }

    toString () {
        return this.#message
    }

    toJSON () {
        return {
            error: this.#error,
            message: this.#message,
            stack: this.#stack,
        }
    }
}

export default ErrorModel
