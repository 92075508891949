import { lazy } from 'solid-js'

export default {
    component: lazy(() => import('../../pages/listing-page/listing-page.jsx')),
    headerStyle: 'checkout',
    path: '/listing/:property_id?',
    showCurrencyHeader: true,
    showFooter: false,
    showHeaderMenu: false,
    showSidebar: false,
}
