// -- External libraries
import { splitProps } from 'solid-js'
import { Dynamic } from 'solid-js/web'
import { A } from '@solidjs/router'

import { useLinkAtomComponentController } from './link-atom-component-controller.js'
import './link-atom-component.scss'

export default function LinkAtomComponent (props) {
    const {
        mergedProps,
    } = useLinkAtomComponentController(props)
    const requiredAttributes = ['external', 'disabled']
    const [
        requestAttributes,
        styles,
        attributes,
    ] = splitProps(mergedProps, requiredAttributes, ['class', 'classList'])

    const rootClasses = ['link-atom-component']
    if (styles.class) rootClasses.push(styles.class)

    if (requestAttributes.disabled) rootClasses.push('link-atom-component--disabled')

    return (
        <Dynamic class={rootClasses.join(' ')} classList={styles.classList} component={requestAttributes.external ? 'a' : A} {...attributes}>
            {attributes.children}
        </Dynamic>
    )
}
