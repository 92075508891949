import BaseAPI from './base-api-client.js'
import { fetchResponseHandler } from './error-utils.js'

export default class UserAPI extends BaseAPI {
    static async show (request) {
        try {
            console.log('UserAPI.show - request:', request)
            const response = await this.fetchSecure('/user', {
                method: 'GET',
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            console.error('UserAPI.show - Error:', error)
            throw error
        }
    }

    static async store (request) {
        try {
            const response = await this.fetch('/user', {
                body: request,
                method: 'POST',
            })

            return fetchResponseHandler(response)
        } catch (error) {
            console.error('UserAPI.store - Error:', error)
            throw error
        }
    }

    static async updateCredentials (request) {
        try {
            const response = await this.fetchSecure('/user/password_update', {
                body: request,
                method: 'POST',
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            console.error('UserAPI.updateCredentials - Error:', error)
            throw error
        }

    }

    static async delete (request) {
        try {
            const response = await this.fetchSecure('/user/delete', {
                body: request,
                method: 'POST',
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            console.error('UserAPI.delete - Error:', error)
            throw error
        }
    }

    static async requestPasswordReset (request) {
        try {
            const response = await this.fetchSecure('/user/request_password_reset', {
                body: request,
                method: 'POST',
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            console.error('UserAPI.requestPasswordReset - Error:', error)
            throw error
        }
    }

    static async passwordReset (token, request) {
        try {
            const response = await this.fetch(`/user/password_reset?token=${token}`, {
                body: request,
                method: 'POST',
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            console.error('UserAPI.passwordReset - Error:', error)
            throw error
        }
    }

    static async requestEmailVerification (request) {
        try {
            const response = await this.fetchSecure('/user/send_confirm_email', {
                body: request,
                method: 'POST',
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            console.error('UserAPI.requestEmailVerification - Error:', error)
            throw error
        }
    }

    static async verifyEmail (token) {
        try {
            const response = await this.fetch(`/user/verify_email?token=${token}`, {
                body: {},
                method: 'POST',
            })


            return await fetchResponseHandler(response)
        } catch (error) {
            console.error('UserAPI.verifyEmail - Error:', error)
            throw error
        }
    }
}