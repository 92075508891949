import BaseObjectStore from './base/base-object-store.js'

class BookingStore extends BaseObjectStore {
    constructor () {
        super({
            priceLoading: false,
            selectedCurrencyCode: '',
            supportedCurrencies: [],
            user: {},
        })
    }

    setCurrencyFromFees (fees) {
        const currencyRegex = /[A-Z]{3}/
        console.log('currencyRegex', currencyRegex)

        for (const fee of fees) {
            const match = fee.amount.match(currencyRegex)

            if (match) {
                this.set({
                    selectedCurrencyCode: match[0],
                })

                return
            }
        }
    }

    getSelectedCurrency () {
        return this.get('supportedCurrencies').find(currency => currency.code === (this.get('selectedCurrencyCode'))  || this.getRegionCurrency())
    }

    getRegionCurrency () {
        const lang = navigator.language;
        if (lang.includes('CA')) return 'CAD';
        if (lang.includes('US')) return 'USD';
    }

    getEmail () {
        return this.get('user').email || 'Lodgr User'
    }
}

const bookingStore = new BookingStore()
export default bookingStore
