import { lazy } from 'solid-js'

export default {
    component: lazy(() => import('../../pages/stays-page/stays-page.jsx')),
    overflow: false,
    path: '/stays/:booking_id?',
    showFooter: false,
    showHeaderMenu: false,
    showSidebar: true,
}
