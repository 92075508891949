import {
    createEffect,
    createSignal,
    onCleanup,
    onMount,
} from 'solid-js'
import { debounce } from '../../../utils'
import bookingStore from '../../../../stores/booking-store.js'
import sessionStore from '../../../../stores/session-store.js'
import CheckoutService from '../../../services/checkout-service.js'
import UserService from '../../../services/user-service.js'

export function useCurrencyDropdownController () {
    // positioning + menu display
    const [
        isOpen,
        setIsOpen,
    ] = createSignal(false)
    const [
        position,
        setPosition,
    ] = createSignal('open-right')

    // Use signals for DOM references
    const [
        dropdownReference,
        setDropdownReference,
    ] = createSignal(null)
    const [
        dropdownMenuReference,
        setDropdownMenuReference,
    ] = createSignal(null)

    const positions = {
        openLeft: 'open-left',
        openRight: 'open-right',
    }

    const toggleDropdown = () => {
        if (isOpen()) {
            setIsOpen(false)
        } else {
            checkPosition()
            setIsOpen(true)

            // Wait for next frame when Solid updates the DOM
            requestAnimationFrame(() => {
                dropdownMenuReference()?.focus()
            })
        }
    }

    const checkPosition = () => {
        const dropdownElement = dropdownReference()
        const dropdownMenuElement = dropdownMenuReference()

        if (!dropdownElement || !dropdownMenuElement) return

        const rect = dropdownElement.getBoundingClientRect()
        const dropdownWidth = dropdownElement.offsetWidth
        const dropdownMenuWidth = dropdownMenuElement.offsetWidth || 200
        const screenWidth = window.innerWidth

        setPosition(
            dropdownMenuWidth - dropdownWidth > screenWidth - rect.right
                ? positions.openLeft
                : positions.openRight,
        )
    }

    const handleResize = debounce(checkPosition, 150)

    const handleClickOutside = (event) => {
        const dropdownElement = dropdownReference()
        const dropdownMenuElement = dropdownMenuReference()

        if (
            dropdownElement
            && !dropdownElement.contains(event.target)
            && (!dropdownMenuElement || !dropdownMenuElement.contains(event.target))
        ) {
            setIsOpen(false)
        }
    }

    // other logic
    const selectCurrency = (currency) => {
        bookingStore.set({
            selectedCurrencyCode: currency.code,
        })
        setIsOpen(false)
    }

    const isBookingReady = () => !bookingStore.get('priceLoading') && bookingStore.getSelectedCurrency()

    // life cycle events
    onMount(async () => {
        window.addEventListener('resize', handleResize)
        document.addEventListener('mousedown', handleClickOutside)

        // get supported currencies from API
        try {
            await CheckoutService.checkCurrency('')
        } catch (error) {
            if (error.data?.supported_currencies) {
                bookingStore.set({
                    supportedCurrencies: error.data?.supported_currencies,
                })
            }
        }
    })

    onCleanup(() => {
        window.removeEventListener('resize', handleResize)
        document.removeEventListener('mousedown', handleClickOutside)
    })

    createEffect(async () => {
        const accessToken = await sessionStore.getAccessToken()
        if (accessToken) {
            const user = await UserService.show()
            bookingStore.set({
                user,
            })
        }
    })

    return {
        bookingStore,
        dropdownMenuReference,
        dropdownReference,
        isBookingReady,
        isOpen,
        position,
        positions,
        selectCurrency,
        setDropdownMenuReference,
        setDropdownReference,
        setIsOpen,
        toggleDropdown,
    }
}
