import { render } from 'solid-js/web'
import './styles/main.scss'
import { Router } from '@solidjs/router'
import {
    ErrorBoundary,
    onMount,
    For,
} from 'solid-js'
import errorStore from './stores/error-store.js'
import ErrorModal from './app/modals/error-modal/error-modal.jsx'
import PublicTemplateComponent from './app/components/templates/public-template/public-template-component.jsx'
import { useAuthSync } from './app/routes/router-utils.js'

render(() => {
    onMount(() => {
        useAuthSync()
    })

    return (
        <Router>
            <PublicTemplateComponent>
                <For each={errorStore.getErrors()}>
                    {error => <ErrorModal error={error} onClose={() => errorStore.removeError(error)} />}
                </For>
                <ErrorBoundary
                    fallback={(error) => {
                        console.error(error)
                        errorStore.addError(error, 'Application UI error, cannot build')
                    }}
                />
            </PublicTemplateComponent>
        </Router>
    )
}, app)
