// -- External libraries
import { mergeProps } from 'solid-js'

export function useLinkAtomComponentController (props) {
    const defaultProps = {}
    const mergedProps = mergeProps(defaultProps, props)

    return {
        mergedProps,
    }
}
