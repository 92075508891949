import BaseObjectStore from './base/base-object-store.js'

class SessionStore extends BaseObjectStore {
    constructor (session = {}) {
        super({
            numOfBookings: 0,
            numOfProperties: 0,
            numOfStays: 0,
            passwordAttempts: 5,
        })
    }

    getSession () {
        return this.getState()
    }

    setSession (session) {
        this.set(session)
    }
}

const sessionStore = new SessionStore()
export default sessionStore
